// @ts-nocheck
import { ApplyPluginsType, dynamic } from '/root/holoagi/cms/node_modules/@umijs/runtime';
import { plugin } from './plugin';

const routes = [
  {
    "path": "/404",
    "exact": true,
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx'), loading: require('@/Loading').default})
  },
  {
    "path": "/cms/login",
    "exact": true,
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__login__index' */'@/pages/cms/login/index.tsx'), loading: require('@/Loading').default})
  },
  {
    "path": "/cms/news/add",
    "exact": true,
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__news__add__index' */'@/pages/cms/news/add/index.tsx'), loading: require('@/Loading').default})
  },
  {
    "path": "/cms/news",
    "exact": true,
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cms__news__index' */'@/pages/cms/news/index.tsx'), loading: require('@/Loading').default})
  },
  {
    "path": "/",
    "exact": true,
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'@/pages/index.tsx'), loading: require('@/Loading').default})
  }
];

// allow user to extend routes
plugin.applyPlugins({
  key: 'patchRoutes',
  type: ApplyPluginsType.event,
  args: { routes },
});

export { routes };
