import './index.less'
import React, { memo } from 'react'
import { Spin } from 'antd'

export interface SpinLoadingIF {
	spinning: boolean
	delay?: number
	indicator?: React.ReactElement<HTMLElement>
	size?: 'small' | 'default' | 'large'
	tip?: string
	wrapperClassName?: string
}

function SpinLoading(props: SpinLoadingIF) {
	const { spinning, tip } = props
	const className = spinning ? 'spin-loading-hooks spin-loading-show' : 'spin-loading-hooks'
	const tipText = tip || '数据加载中...'

	return (
		<div className={className}>
			<Spin tip={tipText} spinning={spinning} />
		</div>
	)
}
export const SpinLoadingHooks = memo(SpinLoading)
