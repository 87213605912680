import React, { memo } from 'react'
import { SpinLoadingHooks } from '@/compoments/SpinLoading'

export default memo(() => {
	return (
		<div className='wh posRel'>
			<SpinLoadingHooks spinning tip='加载中...' />
		</div>
	)
})
